<template>
  <div class="page1">
    <infoList v-if="result.boothInformation" :list="boothBaseList" :result="result.boothInformation">摊位信息</infoList>
    <infoList :list="basicBaseList" :result="result.tenant" v-if="$route.query.isRentCn == '已租'&&result.tenant">商户信息</infoList>
    <infoList :list="contractBaseList" :result="result.contract" v-if="$route.query.isRentCn == '已租'&&result.contract">合同信息</infoList>
    <div class="title" v-if="$route.query.isRentCn !== '已租' && result&&buttonCn.indexOf('费用标准')!=-1">费用标准</div>
    <div v-if="$route.query.isRentCn !== '已租' && result&&(buttonCn.indexOf('费用标准')!=-1)">
      <ETable
        :tableTitle="tableTitle"
        :tableData="result.charge && result.charge.items"
        :needPagination="false"
        :needSelect="false"
        :arraySpanMethod="arraySpanMethod"
        :tableRowClassName="tableRowClassName"
      ></ETable>
    </div>
    <!-- <div class="title">证照信息</div>
    <div v-for="item in result.photoList" v-if="result.photoList">
      <infoList :list="photoBaseList" :result="item" v-if="item.tenantCardPhotoName == '营业执照'"></infoList>
      <infoList :list="photoBaseList2" :result="item" v-else></infoList>
    </div>

    <infoList v-if="result" :list="boothBaseList" :result="result.boothInfo">摊位信息</infoList>
    <div class="title">商户信息变更历史</div>
    <div v-for="item in result.tenantChangeList">
      <infoList :list="basicBaseChangeList" :result="item"></infoList>
    </div>
    <div class="title" v-if="$route.name !== 'RefundRent'">合同收费信息</div>
    <div v-if="$route.name !== 'RefundRent'">
      <ETable :tableTitle="tableTitle" :tableData="result.itemList" :needPagination="false" :needSelect="false"> </ETable>
    </div>
    <div class="title" v-if="$route.name !== 'RefundRent'">合同收费标准</div>
    <div v-if="$route.name !== 'RefundRent'">
      <ETable :tableTitle="tableTitle2" :tableData="result.itemList" :needPagination="false" :needSelect="false"> </ETable>
    </div>-->
    <!--    <infoList v-if="result" :list="basicBaseChangeList" :result="result.tenantChangeInfo">商户信息变更历史</infoList>-->
    <!-- <div class="title" v-if="$route.name!=='RefundRent'">合同收费标准</div>
    <div v-if="$route.name!=='RefundRent'">
      <ETable :tableTitle="tableTitle2" :tableData="result.itemList" :needPagination="false" :needSelect="false"> </ETable>
    </div>-->
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import infoList from '@/components/infoList';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import vxRule from '@/assets/js/formValidate';
  import { otherMixin } from '@/components/mixin';
  import * as math from 'mathjs'
  export default {
    name: 'Login',
    mixins: [otherMixin],
    data() {
      return {
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        photoInfoFormColumns: [
          {
            title: '证照编号：',
            type: 'input',
            property: 'placeName',
            placeholder: '',
            show: true,
          },
          {
            title: '有效期自：',
            type: 'datePicker',
            property: 'validBeginDate',
            show: true,
          },
          {
            title: '证照名称：',
            type: 'select',
            property: 'tenantCardPhotoName',
            placeholder: '',
            show: true,
            disabled: true,
            options: [
              {
                label: '营业执照',
                value: 1,
              },
            ],
          },
          {
            title: '有效期至：',
            type: 'datePicker',
            property: 'validEndDate',
            show: true,
          },
          {
            title: '法人代表:',
            type: 'input',
            property: 'placeName',
            placeholder: '',
            show: true,
          },
          {
            title: '身份证号码:',
            type: 'select',
            property: 'idCard',
            placeholder: '',
            show: true,
          },
          {
            title: ' 经营范围：',
            type: 'select',
            property: 'businessScopeId',
            placeholder: '',
            show: true,
          },
          {
            title: ' 类别：',
            type: 'radio',
            property: 'placeName',
            placeholder: '',
            show: true,
            options: [
              {
                label: '个体',
                value: 1,
              },
              {
                label: '公司',
                value: 2,
              },
            ],
          },
          {
            title: ' 说明：',
            type: 'textarea',
            property: 'description',
            placeholder: '请输入说明',
            show: true,
          },
          {
            title: '  照片：',
            type: 'mixinUpLoad',
            property: 'photoUrl',
            placeholder: '',
            show: true,
          },
          {
            title: '状态：',
            type: 'select',
            property: 'status',
            placeholder: '',
            show: true,
            options: [
              {
                label: '设立中',
                value: 1,
              },
              {
                label: '变更中',
                value: 2,
              },
              {
                label: '注销中',
                value: 2,
              },
              {
                label: '开业',
                value: 2,
              },
              {
                label: '异地',
                value: 2,
              },
            ],
          },
        ],
        photoInfoForm: {
          businessScopeId: '',
          validBeginDate: '',
          validEndDate: '',
          tenantCardPhotoName: '',
          idCard: '',
          description: '',
          status: '',
          photoUrl: '',
        },
        licenceList: [],
        form: {
          id: '',
          tenantName: '',
          phone: '',
          signPerson: '',
          businessScopeId: '',
          idCard: '',
          tenantType: '',
          birthPlace: '',
          idCardPath: '',
        },
        dialogForm: {},
        //合同信息
        contractBaseList: [
          {
            label: '合同编号',
            property: 'contractCode',
          },
          {
            label: '合同类型',
            property: 'contractTypeCn',
          },
          {
            label: '生效日期',
            property: 'inDate',
          },
          {
            label: '到期期至',
            property: 'expireDate',
          },
          {
            label: '主营业务',
            property: 'rentPurpose',
          },
        ], //商户原始信息
        basicBaseList: [
          {
            label: '商户名称',
            property: 'tenantName',
          },
          {
            label: '身份证号码',
            property: 'idCard',
          },
          {
            label: '商户类型',
            property: 'tenantTypeCn',
          },
          {
            label: '经营范围',
            property: 'businessScopeName',
          },
          {
            label: '负责人姓名',
            property: 'signPerson',
          },
          {
            label: '联系电话',
            property: 'phone',
          },
          {
            label: '负责人籍贯',
            property: 'birthPlace',
          },
          {
            label: '录入人',
            property: 'createUserCn',
          },
          {
            label: '录入时间',
            property: 'createTime',
          },
          {
            label: '最近一次修改人',
            property: 'updateUserCn',
          },
          {
            label: '最近一次修改时间',
            property: 'updateTime',
          },
          {
            label: '身份证照片',
            property: 'idCardPath',
            type: 'imgList',
          },
        ], //变更基本信息
        basicBaseChangeList: [
          {
            label: '商户名称',
            property: 'tenantName',
          },
          {
            label: '身份证号码',
            property: 'idCard',
          },
          {
            label: '商户类型',
            property: 'tenantTypeCn',
          },
          {
            label: '经营范围',
            property: 'businessScopeName',
          },
          {
            label: '负责人姓名',
            property: 'signPerson',
          },
          {
            label: '联系电话',
            property: 'phone',
          },
          {
            label: '负责人籍贯',
            property: 'birthPlace',
          },
          {
            label: '最近一次修改人',
            property: 'updateUserCn',
          },
          {
            label: '最近一次修改时间',
            property: 'updateTime',
          },
          {
            label: '变更原因',
            property: 'changeReason',
          },
          {
            label: '身份证照片',
            property: 'idCardPath',
            type: 'imgList',
          },
        ], //基本信息
        boothBaseList: [
          //摊位信息
          {
            label: '摊位号',
            property: 'boothCode',
          },
          {
            label: '状态',
            property: 'isRentCn',
          },
          {
            label: '用途',
            property: 'boothUseName',
          },
          {
            label: '面积',
            property: 'areas',
          },
          {
            label: '摊位类型',
            property: 'boothTypeName',
          },
          {
            label: '区域',
            property: 'areaName',
          },
          {
            label: '位置',
            property: 'placeName',
          },
        ],
        result: {
          photoList: [],
          tenantName: '',
          boothCode: '',
        },
        //证照经营
        photoBaseList: [
          {
            label: '名称',
            property: 'operatorName',
          },
          {
            label: '证照名称',
            property: 'tenantCardPhotoName',
          },
          {
            label: '统一社会信用代码',
            property: 'idCard',
          },
          {
            label: '住所',
            property: 'address',
          },
          {
            label: '身份证号码',
            property: 'idCard',
          },
          {
            label: '类别',
            property: 'ttypeCn',
          },
          {
            label: '登记机关',
            property: 'registerOffice',
          },
          {
            label: '经营项目',
            property: 'businessScope',
          },
          {
            label: '签发日期',
            property: 'issuerDate',
          },
          {
            label: '注册资本',
            property: 'registeredCapital',
          },
          {
            label: '经营期限',
            property: 'validDateStr',
          },
          {
            label: '成立日期',
            property: 'registerDate',
          },
          {
            label: '照片',
            property: 'photoUrl',
            type: 'imgList',
          },
        ],
        //食品证照
        photoBaseList2: [
          {
            label: '经营者名称',
            property: 'operatorName',
          },
          {
            label: '证照名称',
            property: 'tenantCardPhotoName',
          },
          {
            label: '统一社会信用代码（身份证号码）',
            property: 'idCard',
          },
          {
            label: '许可证编号',
            property: 'tenantCardPhotoCode',
          },
          {
            label: '住所',
            property: 'address',
          },
          {
            label: '日常监督管理机构',
            property: 'superviseOrganization',
          },
          {
            label: '经营场所',
            property: 'premises',
          },
          {
            label: '日常监督管理人员',
            property: 'supervisePerson',
          },
          {
            label: '主体业态',
            property: 'mainWork',
          },
          {
            label: '投诉举报电话',
            property: 'lineTelephone',
          },
          {
            label: '经营项目',
            property: 'businessScope',
          },
          {
            label: '发证机关',
            property: 'registerOffice',
          },
          {
            label: '签发日期',
            property: 'issuerDate',
          },
          {
            label: '签发人',
            property: 'issuerPerson',
          },
          {
            label: '有效期至',
            property: 'validEndDate',
          },
          {
            label: '照片',
            property: 'photoUrl',
            type: 'imgList',
          },
        ],
        tableData: [],
        tableTitle: [
          {
            label: '收费项目名称',
            prop: 'name',
          },
          {
            label: '金额',
            prop: 'amount',
          },
          {
            label: '单位',
            prop: 'unit',
          },
          {
            label: '合计（年）',
            prop: 'totalAmount',
          },
        ],
        tableTitle2: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName',
          },
          {
            label: '金额',
            prop: 'year',
          },
          {
            label: '单位',
            prop: 'month',
          },
          {
            label: '合计（年）',
            prop: 'feeBeginDate',
          },
        ],
        buttonCn:[]
      };
    },
    watch: {},
    created() {
      this.form.boothId = Number(this.$route.query.boothInformationId);
      this.buttonCn=JSON.parse(JSON.stringify(this.$route.query.buttonCn))
      this.boothDetail();
    },
    components: { ETable, EButton, EForm, infoList },
    beforeMount() {},
    methods: {
      //合计行合并列
      arraySpanMethod({ row, column, rowIndex, columnIndex }) {
        if (this.result && this.result.charge && this.result.charge.items && this.result.charge.items.length == rowIndex + 1) {
          if (columnIndex === 1) {
            return [1, 4];
          }
        }
      },
      //合计行居中
      tableRowClassName({ row, rowIndex }) {
        if (this.result && this.result.charge && this.result.charge.items && this.result.charge.items.length == rowIndex + 1) {
          return 'center';
        }
        return '';
      },
      cancelDialog() {
        this.dialogVisible = false;
        this.dialogForm = {
          photoName: '',
        };
        this.$refs.form.$refs.form.resetFields();
      },
      onSummit(tenantCardPhotoName) {
        console.log(tenantCardPhotoName);
        this.photoInfoForm.tenantCardPhotoName = tenantCardPhotoName;
        this.dialogVisible = false;
        this.licenceList.push(this.photoInfoFormColumns);
      },
      //获取摊位信息详情
      async boothDetail() {
        console.log(this.id);
        let res = await Http.boothDetail({ id: this.form.boothId });
        if (res.code == 200) {
          this.result = res.data;
          if (this.result && this.result.charge && this.result.charge.items) {
            const list = this.result.charge.items;
            let sum = 0;
            list.forEach((item) => {
              sum = sum + item.totalAmount;
            });
            this.result.charge.items.push({ totalAmount: '', amount: null, name: '合计：' + math.round(sum, 2), unit: '' });
          }
          if(this.result.tenant.idCardPath){
            this.result.tenant.idCardPath = this.result.tenant.idCardPath.split(',');
          }
        }
      },
      saveData() {
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          console.log(object);
          if (valid) {
            this.contractChangeoSubmit();
          } else {
            return false;
          }
        });
      },
      //更新合同
      async contractChangeoSubmit() {
        let res = await Http.contractChange(this.form);
        if (res.code == 200) {
          if (this.licenceList.length == 0) {
            //没有证照信息直接返回
            this.$message.success(res.msg);
            this.$router.push({ name: 'ContractList' });
          }
        }
      },
    },
  };
</script>

<style lang="scss">
.title {
  font-weight: bold;
  padding-left: 10px;
  border-left: 2px solid #4d68ff;
  margin-bottom: 20px;
}
.small-title {
  margin-left: 10px;
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.center {
  td:nth-child(2) {
    .cell {
      text-align: center;
    }
  }
}
</style>
